import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(0, 3),
    '&:after': {
      content: `""`,
      margin: `0 auto`,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      display: `block`,
      width: theme.spacing(10),
      height: `1px`,
      background: theme.palette.primary.main,
      zIndex: -1,
    },
  },
  image: {
    float: `left`,
    width: theme.spacing(50),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down(`sm`)]: {
      width: `100%`,
      marginBottom: theme.spacing(3),
    },
  },
  content: {
    overflowWrap: `normal`,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down(`sm`)]: {
      padding: 0,
    },
  },
  text: {
    whiteSpace: `pre-line`,
    textAlign: `justify`,
    lineHeight: theme.spacing(0.25),
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(3),
    },
  },
}));

interface IntroProps {
  title: string;
  body: any[];
  image: IGatsbyImageData;
}

const Intro: React.FC<IntroProps> = ({ title, body, image }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="md" style={{ padding: 0 }}>
      <Typography
        variant="h5"
        align="center"
        className={classes.title}
        gutterBottom
      >
        {title}
      </Typography>

      <Box className={classes.content}>
        <GatsbyImage className={classes.image} image={image} alt={title} />
        <Typography className={classes.text} variant="body1" paragraph>
          {body[0]}
        </Typography>
        <Typography className={classes.text} variant="body1" paragraph>
          {body[1]}
        </Typography>
        <Typography className={classes.text} variant="body1" paragraph>
          {body[2]}
        </Typography>
      </Box>
    </Container>
  );
};

export default Intro;
