import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import { IContent } from '@/types';

interface StandardsStyle {
  index?: number | boolean;
}

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(0, 3),
    '&:after': {
      content: `""`,
      margin: `0 auto`,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      display: `block`,
      width: theme.spacing(10),
      height: `1px`,
      background: theme.palette.primary.main,
      zIndex: -1,
    },
  },
  content: {
    overflowWrap: `normal`,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down(`sm`)]: {
      padding: 0,
    },
  },
  text: {
    whiteSpace: `pre-line`,
    textAlign: `justify`,
    lineHeight: theme.spacing(0.25),
  },
  standardContainer: {
    display: `flex`,
    justifyContent: `space-between`,
    background: theme.palette.grey.main,
    padding: 0,
    margin: theme.spacing(8, 3),
    width: `auto`,
    [theme.breakpoints.down(`xs`)]: {
      margin: theme.spacing(4, 0),
    },
  },
  textBox: {
    display: `block`,
    maxWidth: `90%`,
    padding: theme.spacing(4, 2),
    [theme.breakpoints.down(`xs`)]: {
      maxWidth: `95%`,
    },
  },
  sideBar: {
    width: theme.spacing(1),
    background: ({ index }: StandardsStyle) => {
      const colors = [
        theme.palette.primary.main,
        theme.palette.grey.dark,
        theme.palette.secondary.light,
      ];
      return index === false
        ? theme.palette.grey.main
        : colors[(index as number) % 3];
    },
  },
}));

interface SidebarProp {
  index?: number;
}

const Sidebar: React.FC<SidebarProp> = ({ index }) => {
  const classes = useStyles({ index });

  return <div className={classes.sideBar} />;
};

interface StandardProps {
  title: string;
  subtitle: string;
  index: number;
}

const Standard: React.FC<StandardProps> = ({ title, subtitle, index }) => {
  const classes = useStyles({});

  return (
    <Container className={classes.standardContainer}>
      <Sidebar index={index % 2 === 1 && index} />
      <Box className={classes.textBox}>
        <Typography variant="h5" className={classes.text}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.text}>
          {subtitle}
        </Typography>
      </Box>
      <Sidebar index={index % 2 === 0 && index} />
    </Container>
  );
};

interface StandardsProps {
  title: string;
  contents: IContent[];
}

const Standards: React.FC<StandardsProps> = ({ title, contents }) => {
  const classes = useStyles({});

  return (
    <Container maxWidth="md" style={{ padding: 0 }}>
      <Typography
        variant="h5"
        align="center"
        className={classes.title}
        gutterBottom
      >
        {title}
      </Typography>

      <Box className={classes.content}>
        {contents.map((content, index) => (
          <Standard
            title={content.title}
            subtitle={content.subtitle}
            index={index}
          />
        ))}
      </Box>
    </Container>
  );
};

export default Standards;
