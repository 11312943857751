import React from 'react';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { makeStyles, Box, Hidden, Typography } from '@material-ui/core';
import { IFormData } from '@/types';
import Form from '@/components/contact/Form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  form: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    padding: theme.spacing(13, 4),
    width: `52%`,
    height: theme.spacing(100),
    [theme.breakpoints.down(`md`)]: {
      width: `100%`,
    },
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(10, 0),
    },
  },
  image: {
    height: `100%`,
    width: `100%`,
  },
  imageBox: {
    width: `48%`,
  },
  root: {
    display: `flex`,
    height: `100%`,
    width: `100%`,
  },
  text: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up(`xl`)]: {
      textAlign: `center`,
    },
    [theme.breakpoints.down(`md`)]: {
      paddingLeft: 0,
      textAlign: `center`,
    },
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(0, 3),
      paddingBottom: theme.spacing(2),
    },
  },
}));

interface ContactFormProps {
  image: IGatsbyImageData;
  subtitle: string;
  title: string;
  components: IFormData[];
}

const ContactForm: React.FC<ContactFormProps> = ({
  image,
  subtitle,
  title,
  components,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Hidden mdDown>
        <Box className={classes.imageBox}>
          <GatsbyImage
            alt={title}
            className={classes.image}
            image={getImage(image)}
            objectFit="cover"
            objectPosition="25% 50%"
          />
        </Box>
      </Hidden>
      <Box className={classes.form}>
        <Box className={classes.text}>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1">{subtitle}</Typography>
        </Box>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Form components={components} />
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
};

export default ContactForm;
