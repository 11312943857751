import React from 'react';
import { makeStyles, Typography, Box, Container } from '@material-ui/core';
import { IEmployee } from '@/types';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  card: {
    display: `flex`,
    flexDirection: `column`,
    margin: theme.spacing(5),
    width: theme.spacing(38),
    [theme.breakpoints.only(`md`)]: {
      margin: 0,
      width: theme.spacing(33),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.only(`sm`)]: {
      margin: theme.spacing(5),
      width: theme.spacing(33),
    },
    [theme.breakpoints.only(`xs`)]: {
      margin: theme.spacing(7),
      width: theme.spacing(33),
    },
  },
  image: {
    borderRadius: theme.spacing(100),
    height: 0,
    marginBottom: theme.spacing(2.7),
    paddingBottom: `70%`,
    position: `relative`,
    width: `70%`,
  },
  imageBox: {
    alignItems: `center`,
    display: `flex`,
    justifyContent: `center`,
  },
  root: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    margin: `0 auto`,
    maxWidth: theme.breakpoints.width(`lg`),
    width: `auto`,
    [theme.breakpoints.only(`md`)]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down(`sm`)]: {
      justifyContent: `center`,
    },
  },
  text: {
    height: `100%`,
    width: `100%`,
  },
  textDescription: {
    color: `black`,
    fontFamily: `Avenir Roman`,
    fontSize: theme.spacing(2.3),
    [theme.breakpoints.down(`md`)]: {
      fontSize: theme.spacing(2.2),
    },
  },
  textName: {
    color: theme.palette.primary.main,
    fontWeight: `bold`,
  },
  textPosition: {
    marginBottom: theme.spacing(2.7),
  },
}));

interface TeamCardProps {
  description: string;
  image: IGatsbyImageData;
  name: string;
  position: string;
}

const TeamCard: React.FC<TeamCardProps> = ({
  description,
  image,
  name,
  position,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Box className={classes.imageBox}>
        <GatsbyImage
          className={classes.image}
          image={getImage(image)}
          alt={name}
        />
      </Box>
      <Box className={classes.text}>
        <Typography className={classes.textName} variant="h4" align="center">
          {name}
        </Typography>
        <Typography
          className={classes.textPosition}
          variant="subtitle2"
          align="center"
        >
          {position}
        </Typography>
        <Typography
          align="center"
          className={classes.textDescription}
          variant="body2"
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

interface TeamCardsProps {
  staff: IEmployee[];
}

const TeamCards: React.FC<TeamCardsProps> = ({ staff }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="lg">
      {staff.map(({ description, firstName, lastName, position, image }) => (
        <Box key={firstName + lastName} style={{ margin: 0, border: 0 }}>
          <TeamCard
            description={description}
            image={image}
            name={`${firstName} ${lastName}`}
            position={position}
          />
        </Box>
      ))}
    </Container>
  );
};

export default TeamCards;
