import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout, Header, Section, Helmet } from '@/components';
import { Intro, Standards, ContactForm } from '@/components/about';
import { IContent, IHeader } from '@/types';
import { parseFormJSON } from '@/helpers';

const useStyles = makeStyles((theme) => ({
  greenBackground: {
    backgroundColor: theme.palette.primary.light,
  },
  noPadding: {
    padding: 0,
  },
  noXPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const About: React.FC<PageProps> = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const sections = data.page.children;
  const header = sections[0] as IHeader;
  const introduction = sections[1].children[0] as IContent;
  const standards = sections[2];
  const contactForm = sections[3].children[0] as IContent;

  return (
    <main>
      <Helmet page="About Us" />
      <Layout>
        <Header
          title={t(header.title)}
          subtitle={t(header.subtitle)}
          images={[header.image]}
        />
        <Section className={classes.noXPadding}>
          <Intro
            title={t(introduction.title)}
            body={introduction.body.map(t)}
            image={getImage(introduction.image)}
          />
        </Section>
        <Section className={classes.noXPadding}>
          <Standards title={t(standards.title)} contents={standards.children} />
        </Section>
        <Section className={`${classes.noPadding} ${classes.greenBackground}`}>
          <ContactForm
            image={contactForm.image}
            subtitle={t(contactForm.subtitle)}
            title={t(contactForm.title)}
            components={parseFormJSON(contactForm.formData)}
          />
        </Section>
      </Layout>
    </main>
  );
};

export default About;

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page(id: { eq: "About" }) {
      id
      children {
        ... on Header {
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        ... on Section {
          title
          children {
            ... on Content {
              title
              subtitle
              body
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              formData
            }
            ... on Employee {
              id
              lastName
              position
              firstName
              description
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      }
    }
  }
`;
